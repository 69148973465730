
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
int,
textarea {
  font-family: "Poppins", sans-serif;
}

.containerData {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: #fafafa;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for the first row */
  grid-template-rows: auto auto;  /* Two rows: First row will have two columns, second row will span both columns */
}

.contact-info {
  grid-column: 1 / span 1; /* First column in the first row */
}

.contact-form {
  grid-column: 2 / span 1; /* Second column in the first row */
}

.footer {
  grid-column: 1
}

.contact-form {
  background: linear-gradient(to right, #4f46e5, #7e22ce); /* Gradient from Indigo to Purple */
  position: relative;
  color: #fff; /* White text color */
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #7e22ce);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #7e22ce; /* Match gradient color */
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  /* padding: 2.3rem 2.2rem; */
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.int-containerData {
  position: relative;
  margin: 1rem 0;
}

.int {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

textarea.int {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.int-containerData label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.int-containerData.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn {
  padding: 0.6rem 1.3rem;
  background: linear-gradient(90deg, rgb(88, 136, 245) 0%, rgba(160, 54, 193, 0.9) 50.15%, rgba(233, 53, 166, 0.9) 100.31%); /* Gradient background */
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #fff; /* Text color white */
  font-family: 'Roboto', sans-serif; /* Roboto font */
  font-weight: bold; /* Font weight */
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
  padding-top: 1rem; /* Padding from your style */
  padding-bottom: 1rem;
}

.btn:hover {
  background-color: transparent;
  color: #fff;
}

.btnMain {
  padding: 0.6rem 1.3rem;
  background: linear-gradient(90deg, rgba(39, 60, 0, 0.9) 0%, rgba(160, 54, 193, 0.9) 50.15%, rgba(233, 53, 166, 0.9) 100.31%); /* Gradient background */
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #fff; /* Text color white */
  font-family: 'Roboto', sans-serif; /* Roboto font */
  font-weight: bold; /* Font weight */
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
  padding-top: 1rem; /* Padding from your style */
  padding-bottom: 1rem;
}

.btnMain:hover {
  background-color: transparent;
  color: #fff;
}


.int-containerData span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.int-containerData span:before,
.int-containerData span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #7e22ce; /* Match gradient color */
  top: 50%;
  transform: translateY(-50%);
}

.int-containerData span:before {
  left: 50%;
}

.int-containerData span:after {
  right: 50%;
}

.int-containerData.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.int-containerData.focus span:before,
.int-containerData.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #4f46e5; /* Indigo color */
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.information i {
  color: #4f46e5; /* Indigo color */
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #4f46e5, #7e22ce); /* Gradient from Indigo to Purple */
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #4f46e5; /* Indigo color */
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #4f46e5, #7e22ce); /* Gradient from Indigo to Purple */
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .containerData {
    padding: 1.5rem;
  }

  .contact-form:before {
    width: 18px;
    height: 18px;
    top: 15px;
    left: -8px;
  }

  .circle.one {
    width: 100px;
    height: 100px;
    top: 90px;
    right: -25px;
  }

  .circle.two {
    width: 60px;
    height: 60px;
    top: 0px;
    right: 20px;
  }

  .big-circle {
    width: 400px;
    height: 400px;
    bottom: 65%;
    transform: scale(0.7) translate(-40%, 30%);
    right: 30%;
  }

  .square {
    height: 250px;
    transform: translate(0%, 0%);
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
.status-message {
  margin-top: 1rem;
  font-size: 1rem;
  color: #ff0000; /* Red for error messages */
}

.status-message.success {
  color: #4caf50; /* Green for success messages */
}


body {
  user-select: none; /* For modern browsers */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
}

/* Allow pointer events on specific interactive elements */
button, input, select, textarea {
  pointer-events: auto; /* Enable pointer events on these elements */
}

@import url(https://fonts.googleapis.com/css?family=Raleway:300);

:root {
  --btn-border: 1px solid rgba(255, 255, 255, 0.2);
  --btn-bg: transparent;
  --btn-text-color: #f4f4f4;
}

.btn-alt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* width: 250px; */
  font-size: 1.1rem;

  position: relative;
  overflow: hidden;

  border-radius: 4px;
  text-decoration: none;
  border: var(--btn-border);
  color: var(--btn-text-color);
  background: var(--btn-bg);

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
  }

  &::before {
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    left: 0;
    filter: blur(30px);
    animation: shine-alt1 5s ease-in-out infinite;
  }

  &::after {
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    left: 30px;
    filter: blur(5px);
    animation: shine-alt2 5s ease-in-out infinite;
  }
}

@keyframes shine-alt1 {
  0% {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-15deg);
    transition-property: opacity, transform;
  }

  100% {
    opacity: 0.6;
    transform: translateX(300px) skewX(-15deg);
    transition-property: opacity, transform;
  }
}

@keyframes shine-alt2 {
  0% {
    opacity: 0;
    transform: translateX(-100px) skewX(-15deg);
    transition-property: opacity, transform;
  }

  100% {
    opacity: 1;
    transform: translateX(300px) skewX(-15deg);
    transition-property: opacity, transform;
  }
}




